import { TravelAndExpenseStatus } from "../enums/courseRefund.enum";

export const courseParticipantExpenseStatusNorwegian = {
  [TravelAndExpenseStatus.Draft]: "Utkast",
  [TravelAndExpenseStatus.ToApproval]: "Til godkjenning",
  [TravelAndExpenseStatus.Approved]: "Godkjent",
  [TravelAndExpenseStatus.Rejected]: "Avvist",
  [TravelAndExpenseStatus.OrderCreated]: "Ordre opprettet",
} as const;

export const getCourseParticipantExpenseStatusByStatus = (statusCode: TravelAndExpenseStatus) =>
  courseParticipantExpenseStatusNorwegian[statusCode];
